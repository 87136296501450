



























































/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Vue } from 'vue-property-decorator'
import Editor from '@/components/Editor/index.vue'
import { Upload } from 'element-ui'
Vue.use(Upload)

@Component({
  components: {
    Editor
  }
})
export default class extends Vue {
  $constants: any
  // 字典数据
  // 展示类型
  showTypeDicts: any = []
  info = {
    id: '',
    title: '',
    pic: '',
    content: ``
  }
  isEditType: any = '' //view详情edit编辑
  showCoverImage = ''
  isHover = false
  isShowDialog = false
  loading = false

  async beforeCreate() {
    // 获取相关字典
  }

  created() {
    if (this.$route.query.isEditType == 'add') {
      this.info = JSON.parse(this.$route.query.info as any)
      this.showCoverImage = this.$constants.common.imgPrefix + this.info.pic
    } else if (this.$route.query.id) {
      this.getDetail(this.$route.query.id as any)
    }

    this.isEditType = this.$route.query.isEditType
  }

  // 获取详情
  getDetail(id: number) {
    this.$api.property.getCarouseDetailById(id).then((res: any) => {
      const data = res.data
      if (data.success) {
        this.showCoverImage = this.$constants.common.imgPrefix + data.data.pic
        this.info = {
          id: data.data.id,
          title: data.data.title,
          pic: data.data.pic,
          content: data.data.content || ''
        }
      }
    })
  }

  // 上传成功
  uploadSuccess(res: any) {
    this.info.pic = res.data
  }
  handleAvatarChange(file: any) {
    this.showCoverImage = URL.createObjectURL(file.raw)
  }

  // 上传前
  beforeUpload(file: any) {
    const isJPEG = file.type === 'image/jpeg'
    const isJPG = file.type === 'image/jpg'
    const isPNG = file.type === 'image/png'
    const isLt2M = file.size / 1024 / 1024 < 2

    if (!isJPG && !isJPEG && !isPNG) {
      this.$message.error('上传照片只能是 JPG/JPEG/PNG 格式!')
    }
    if (!isLt2M) {
      this.$message.error('上传单张照片大小不能超过 2MB!')
    }
    return (isJPG || isJPEG || isPNG) && isLt2M
  }

  // 获取富文本内容
  getTxt(data: any) {
    this.info.content = data
  }

  // 返回
  goback() {
    this.$router.push('/property/carousel')
  }

  // 保存
  save() {
    if (!this.info.title || !this.info.pic) {
      this.$message.error('请输入必填项')
      return
    }
    this.loading = true
    if (this.$route.query.isEditType == 'edit') {
      // 编辑
      this.$api.property
        .editCarouse({
          ...this.info
        })
        .then((res: any) => {
          this.loading = false
          const data = res.data
          if (data.success) {
            this.$message.success(data.msg)
            this.goback()
          } else {
            this.$message.error(data.msg)
          }
        })
        .catch(() => {
          this.loading = false
        })
    } else {
      this.$api.property
        .addCarouse({
          ...this.info
        })
        .then((res: any) => {
          this.loading = false
          const data = res.data
          if (data.success) {
            this.$message.success(data.msg)
            this.goback()
          } else {
            this.$message.error(data.msg)
          }
        })
        .catch(() => {
          this.loading = false
        })
    }
  }
}
